import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxMultiSection from '../../templates/mdx-multi-section.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const NavSecondary = makeShortcode("NavSecondary");
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxMultiSection;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <a id="projects"></a>
    <section className="main style1">
      <header className="small">
        <h2 {...{
          "id": "emrg-projects"
        }}>{`EMRG: Projects`}</h2>
        <p><a parentName="p" {...{
            "href": "https://jc.embodied.net"
          }}><span className="text-nowrap">{`Professor John Crawford`}</span></a>{` and associates in UC Irvine's `}<span className="text-nowrap">{`Embodied Media Research Group`}</span>{` (EMRG) work in a diverse, collaborative framework, developing performances. exhibitions and artistic research projects that incorporate tangible, sensorial and experiential outcomes.`}</p>
      </header>
      <div className="image filtered" data-position="center">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "2400px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "33.33333333333333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAHKBrITX//EABgQAAMBAQAAAAAAAAAAAAAAAAECEQAD/9oACAEBAAEFAnehelBff//EABgRAAIDAAAAAAAAAAAAAAAAAAABAhIT/9oACAEDAQE/AaIzif/EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAgEBPwF0nb//xAAZEAABBQAAAAAAAAAAAAAAAAABABARITH/2gAIAQEABj8CFKBrf//EABgQAQEBAQEAAAAAAAAAAAAAAAERACFR/9oACAEBAAE/IS4tMAicWPmq9C7/2gAMAwEAAgADAAAAEIfP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhUf/aAAgBAwEBPxBxg8D/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQExUf/aAAgBAgEBPxBjuiNj/8QAGhABAQADAQEAAAAAAAAAAAAAAREAMUEhUf/aAAgBAQABPxAm04Ipa9wV0mRjF8fphVF1NZ//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dancers performing in Living in the Tempest",
              "title": "Dancers performing in Living in the Tempest",
              "src": "/static/80494a43fb269d16dbe709b443f15bfa/111a0/tempest-2018-2037_3x1.jpg",
              "srcSet": ["/static/80494a43fb269d16dbe709b443f15bfa/3ad8d/tempest-2018-2037_3x1.jpg 600w", "/static/80494a43fb269d16dbe709b443f15bfa/6c738/tempest-2018-2037_3x1.jpg 1200w", "/static/80494a43fb269d16dbe709b443f15bfa/111a0/tempest-2018-2037_3x1.jpg 2400w"],
              "sizes": "(max-width: 2400px) 100vw, 2400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </div>
      <div className="inner special medium">
        <h3 {...{
          "id": "direct-engagement--relevant-impact"
        }}>{`Direct Engagement & Relevant Impact`}</h3>
        <p>{`With a focus on socially engaged artmaking, Prof. Crawford and associates in the Embodied Media Research Group want to do more than entertain people or create beauty for its own sake `}{`—`}{` we want to do these things in the service of relevant impact for social good. In addition to being artists and designers, we are technology developers. Our work explores new forms of expression enabled by emerging technical capabilities including machine intelligence, kinetic media of all kinds, extended reality (XR), interactive software systems and evolving forms of social media connectivity. Directly engaging with new technologies in the context of artistic production can spark development of products and services for positive change. We believe that design methodologies for developing creative solutions should be paired with artistic methodologies that can reveal problems and critique existing structures.`}</p>
        <p>{`Please visit the `}<a parentName="p" {...{
            "href": "https://embodied.net"
          }}>{`portfolio`}</a>{` for more about our projects.`}</p>
      </div> 
      <div className="inner alt"><div className="posts">
          <article>
            <Link to="https://yomo.embodied.net" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "2400px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAAUBAgME/8QAGAEBAAMBAAAAAAAAAAAAAAAAAgABAwT/2gAMAwEAAhADEAAAAZNP0cEZikMaHRhkUv/EAB0QAAIBBAMAAAAAAAAAAAAAAAECAwAQEhMUIjH/2gAIAQEAAQUC1PXHTXLGEZ3DF5VBkfEsOvtv/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/ASv/xAAcEAACAgIDAAAAAAAAAAAAAAAAARESAiBBYYH/2gAIAQEABj8ClpmMrggaK+EVt3p//8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFBUXGR/9oACAEBAAE/IXPW8QpbnSzlIsuAlDfsCaa6QRBoR0M9Qxuyp//aAAwDAQACAAMAAAAQmCh+/8QAGREBAQEAAwAAAAAAAAAAAAAAAQARITFh/9oACAEDAQE/EBV5s9tdjq//xAAYEQEBAQEBAAAAAAAAAAAAAAABABExYf/aAAgBAgEBPxAw9lbyAye3/8QAGxABAQADAAMAAAAAAAAAAAAAAREAITFBYYH/2gAIAQEAAT8QYWW+lfs5gLVS1G916OYMCARbMnmhCa+vLhW1TRy9cKZiUXy4NU6emSyRDKm8AEhDP//Z')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Dancers performing in Your Ocean, My Ocean",
                    "title": "Dancers performing in Your Ocean, My Ocean",
                    "src": "/static/593d2c2d11370279255b400ba76eace9/111a0/yomo-uci-emergence-0106_1x1.jpg",
                    "srcSet": ["/static/593d2c2d11370279255b400ba76eace9/3ad8d/yomo-uci-emergence-0106_1x1.jpg 600w", "/static/593d2c2d11370279255b400ba76eace9/6c738/yomo-uci-emergence-0106_1x1.jpg 1200w", "/static/593d2c2d11370279255b400ba76eace9/111a0/yomo-uci-emergence-0106_1x1.jpg 2400w"],
                    "sizes": "(max-width: 2400px) 100vw, 2400px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <h3 {...{
              "id": "your-ocean-my-ocean"
            }}>{`Your Ocean, My Ocean`}</h3>
  <ul className="actions special">
    <li><Link to="https://yomo.embodied.net" className="button small" mdxType="Link">Details</Link></li>
  </ul>
          </article>
          <article>
            <Link to="https://tempest.embodied.net" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "2400px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAHGeqqmCyT0F0gZT//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMQEhMiQf/aAAgBAQABBQIUsG1M4Gi2KWB7PaFbzH//xAAbEQABBAMAAAAAAAAAAAAAAAAAAQIQERIhMf/aAAgBAwEBPwHd0YIN7H//xAAaEQABBQEAAAAAAAAAAAAAAAABAAIQESFR/9oACAECAQE/ASMVu5P/xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIQERIhQVFy/9oACAEBAAY/AsW2+CS73MLUS9EK/8QAHBAAAgIDAQEAAAAAAAAAAAAAAREAIUFRcWGR/9oACAEBAAE/IWBR2YsEp8jLAH2CBO5OIhhii8Q8AngmHqJcWISlQn//2gAMAwEAAgADAAAAENsogP/EABkRAAIDAQAAAAAAAAAAAAAAAAABEBExUf/aAAgBAwEBPxBWPs4Xp//EABcRAAMBAAAAAAAAAAAAAAAAAAABMRH/2gAIAQIBAT8QvVGt4HDT/8QAHRABAAMBAAMBAQAAAAAAAAAAAQARITFBUXFhsf/aAAgBAQABPxDEdQ2UD6idUCW8V7jTREvSTB+YKNXX3ahvqghhXN/CUM/nD+yi8X0fLcAwaHTxnPkOoey9J//Z')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Dancers performing in Living in the Tempest",
                    "title": "Dancers performing in Living in the Tempest",
                    "src": "/static/21e2576eae265cf3346bb10f1cca068e/111a0/tempest-2018-2103_1x1.jpg",
                    "srcSet": ["/static/21e2576eae265cf3346bb10f1cca068e/3ad8d/tempest-2018-2103_1x1.jpg 600w", "/static/21e2576eae265cf3346bb10f1cca068e/6c738/tempest-2018-2103_1x1.jpg 1200w", "/static/21e2576eae265cf3346bb10f1cca068e/111a0/tempest-2018-2103_1x1.jpg 2400w"],
                    "sizes": "(max-width: 2400px) 100vw, 2400px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <h3 {...{
              "id": "living-in-the-tempest"
            }}>{`Living in the Tempest`}</h3>
  <ul className="actions special">
    <li><Link to="https://tempest.embodied.net" className="button small" mdxType="Link">Details</Link></li>
  </ul>
          </article>
          <article>
            <Link to="https://embodied.net/performative-objects" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "2400px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMEAQIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAGPWGc2FpbzbwzUBv/EABwQAQABBAMAAAAAAAAAAAAAAAECAAMRIhIhI//aAAgBAQABBQLpOCrGWbbtLeMUgWVxAp9H/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwFiK//EABURAQEAAAAAAAAAAAAAAAAAABBB/9oACAECAQE/ASH/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIRECExEv/aAAgBAQAGPwJQXSkcPRa0ypPeW5LZ/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAIUFhMf/aAAgBAQABPyH8tW1mOQX7iMLjm9uZkV9iaEJlXvzdBqzzQJIZd//aAAwDAQACAAMAAAAQXxBA/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQMBAT8QTqrIOln/xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAgEBPxB44Wshqfb/xAAeEAEAAgIDAAMAAAAAAAAAAAABABEhMUFRYXGBsf/aAAgBAQABPxAVnw4dUvhKpLqqv4iTcBpIOvls3iCuEPKuyB98UC40L7iVHvwHEqYQXaq/JpdSKY+n2f/Z')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Students working with Performative Objects",
                    "title": "Students working with Performative Objects",
                    "src": "/static/cd6356b6100a9db3d9e7c430aa54da0a/111a0/tempest-2018-1039_1x1.jpg",
                    "srcSet": ["/static/cd6356b6100a9db3d9e7c430aa54da0a/3ad8d/tempest-2018-1039_1x1.jpg 600w", "/static/cd6356b6100a9db3d9e7c430aa54da0a/6c738/tempest-2018-1039_1x1.jpg 1200w", "/static/cd6356b6100a9db3d9e7c430aa54da0a/111a0/tempest-2018-1039_1x1.jpg 2400w"],
                    "sizes": "(max-width: 2400px) 100vw, 2400px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <h3 {...{
              "id": "performative-objects"
            }}>{`Performative Objects`}</h3>
  <ul className="actions special">
    <li><Link to="https://embodied.net/performative-objects" className="button small" mdxType="Link">Details</Link></li>
  </ul>
          </article>
          <article>
            <Link to="https://embodied.net/sacre-project" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "1377px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECAwQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAMEAf/aAAwDAQACEAMQAAABm3BpO3yl1KpgwzAf/8QAHBAAAQQDAQAAAAAAAAAAAAAAAQIDERIABBAh/9oACAEBAAEFAjrMUOsxCvFBwnC9BXFitRy5HP/EABYRAAMAAAAAAAAAAAAAAAAAAAERIP/aAAgBAwEBPwFCP//EABkRAAEFAAAAAAAAAAAAAAAAAAABEBEiUf/aAAgBAgEBPwG+kK//xAAfEAACAAUFAAAAAAAAAAAAAAAAAQIQETGREiEyUWH/2gAIAQEABj8CbWrJxizJrwpVqhtYuNdy/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAMaEhQYH/2gAIAQEAAT8hGBQYdomChdOSHbWVQjMG1/W5Dpg3gfalzzv/2gAMAwEAAgADAAAAEHDnvf/EABgRAQADAQAAAAAAAAAAAAAAAAEAEBEh/9oACAEDAQE/EMRZya1//8QAGBEBAQADAAAAAAAAAAAAAAAAAQARITH/2gAIAQIBAT8Qw0ILuAnt/8QAHBABAQADAQEBAQAAAAAAAAAAAREAITGBQVFx/9oACAEBAAE/EFuURdbP5jZI0NJcfdo9yfIIWq+LDv5lwZJ6j9I6xlSon0a2eOs9e2A73hh8kqjT647Wf//Z')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Dancers performing in The Sacre Project",
                    "title": "Dancers performing in The Sacre Project",
                    "src": "/static/72a18eaf6d7e379a289f3cbee5250929/bcfdc/sacre_021913_02_1x1.jpg",
                    "srcSet": ["/static/72a18eaf6d7e379a289f3cbee5250929/3ad8d/sacre_021913_02_1x1.jpg 600w", "/static/72a18eaf6d7e379a289f3cbee5250929/6c738/sacre_021913_02_1x1.jpg 1200w", "/static/72a18eaf6d7e379a289f3cbee5250929/bcfdc/sacre_021913_02_1x1.jpg 1377w"],
                    "sizes": "(max-width: 1377px) 100vw, 1377px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <h3 {...{
              "id": "the-sacre-project"
            }}>{`The Sacre Project`}</h3>
  <ul className="actions special">
    <li><Link to="https://embodied.net/sacre-project" className="button small" mdxType="Link">Details</Link></li>
  </ul>
          </article>
          <article>
            <Link to="https://embodied.net/broken-glass" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "1080px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAZ1WTmqUjufoMbgX/8QAGxAAAwEAAwEAAAAAAAAAAAAAAAECEQMEEjH/2gAIAQEAAQUCplbM5B7IpUVifK9Op8P/xAAXEQEBAQEAAAAAAAAAAAAAAAABABAh/9oACAEDAQE/AQLkZ//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABwQAAICAgMAAAAAAAAAAAAAAAABITEQEUFRgf/aAAgBAQAGPwKbK2+imSWcng3j/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBkWH/2gAIAQEAAT8hsHwnQrKh7LfgzkrCu4dYfZYj4ajHcq2Q6tY6z//aAAwDAQACAAMAAAAQXCiA/8QAGBEAAgMAAAAAAAAAAAAAAAAAADEQEWH/2gAIAQMBAT8QYK2PH//EABcRAQEBAQAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8QVyPJ/8QAHRABAQACAgMBAAAAAAAAAAAAAREAIUFhMVFxof/aAAgBAQABPxBWBKjyV4MZIyVOzrpyvc5UbyA2pYH8ctw8Mdh7u8Q8d61p8c9jDirTDfOErgKIP0z/2Q==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A dancer in the screendance Broken Glass",
                    "title": "A dancer in the screendance Broken Glass",
                    "src": "/static/3137e644e1290738a03ab1fefbf0eb5d/44838/broken-glass_06_38_22_1x1.jpg",
                    "srcSet": ["/static/3137e644e1290738a03ab1fefbf0eb5d/3ad8d/broken-glass_06_38_22_1x1.jpg 600w", "/static/3137e644e1290738a03ab1fefbf0eb5d/44838/broken-glass_06_38_22_1x1.jpg 1080w"],
                    "sizes": "(max-width: 1080px) 100vw, 1080px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <h3 {...{
              "id": "broken-glass"
            }}>{`Broken Glass`}</h3>
  <ul className="actions special">
    <li><Link to="https://embodied.net/broken-glass" className="button small" mdxType="Link">Details</Link></li>
  </ul>
          </article>
          <article>
            <Link to="https://embodied.net/when-there-was-water" className="image filtered" mdxType="Link">
              <p><span parentName="p" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "957px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABvjtiy2qK5gxKiP/EAB4QAAICAAcAAAAAAAAAAAAAAAECABIDBBETIiMx/9oACAEBAAEFAiSp3GJVbjMP14fJqLNZ5Lmf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQIBAT8BY//EABoQAAMAAwEAAAAAAAAAAAAAAAABEQIgMVH/2gAIAQEABj8Cy8lLSwTRDun/xAAbEAADAQEBAQEAAAAAAAAAAAAAARExIUFRgf/aAAgBAQABPyFQeI80dAI8u/Gez2o4sp6KW1+DZqPC6WiStP/aAAwDAQACAAMAAAAQ1whC/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEEH/2gAIAQMBAT8QxjJr/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQIBAT8Qp8JjZ//EABwQAQADAQEAAwAAAAAAAAAAAAEAESExYUGBsf/aAAgBAQABPxC94DC2hBFH0iRJuU4ebE0XppH6+IHAPwqxPxju6farkpnVuDZbyxwglgcXBgYJ/9k=')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="span" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A dancer in the screendance When There Was Water",
                    "title": "A dancer in the screendance When There Was Water",
                    "src": "/static/ede6615a1514762f408566f78f2d65f2/9169d/when-there-was-water_03_40_00_1x1.jpg",
                    "srcSet": ["/static/ede6615a1514762f408566f78f2d65f2/3ad8d/when-there-was-water_03_40_00_1x1.jpg 600w", "/static/ede6615a1514762f408566f78f2d65f2/9169d/when-there-was-water_03_40_00_1x1.jpg 957w"],
                    "sizes": "(max-width: 957px) 100vw, 957px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
    `}</span></p>
            </Link>
            <h3 {...{
              "id": "when-there-was-water"
            }}>{`When There Was Water`}</h3>
  <ul className="actions special">
    <li><Link to="https://embodied.net/when-there-was-water" className="button small" mdxType="Link">Details</Link></li>
  </ul>
          </article>
        </div></div>
    </section>
    <section className="main style2">
      <footer>
  <div className="row aln-center gtr-50 gtr-uniform">
    <NavSecondary mdxType="NavSecondary" />
  </div>
  <div className="row">
    <div className="col-12 footnote">
        Additional Image Credits:
        Daniel A. Anderson,
        Leandro Damasco,
        Skye Schmidt
    </div>
  </div>
      </footer>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      